import React, { useState, useEffect, useCallback } from "react";
import { useAppContext } from "../AppContext";
import { DocumentDuplicateIcon, CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid";
import QRCode from "qrcode.react";
import u from "../functions/utils.js";
import nitro from "../functions/nitro.js";

import SubmitButton from "./common/SubmitButton.js";
import HintCircle from "./pay/Hint.js";
import PayHeader from "./pay/PayHeader";

import { isValidUTR } from "../functions/utr.js";

let usedTokens = [];

export default function PayComponent() {
    const { addToast, setError, setLoading, amount, utr, setUtr, app_id, auth_token, turnstile_token, setTurnstile, setTurnstileToken, channel, setPage } =
        useAppContext();

    const [utrValid, setUtrValid] = useState(null);

    const address = channel.upi_address;
    const amtInr = (amount / 100).toFixed(2);
    const upiLink = `upi://pay?pa=${address}&am=${amtInr}&cu=INR`;

    const finishSubmission = async () => {
        try {
            setLoading(true);

            const submission = await nitro.submitUtr(
                { setError, setLoading, setTurnstileToken, turnstile_token, auth_token, app_id },
                { channel_id: channel.channel_id, utr_number: utr }
            );

            if (u.isNonEmptyString(submission?.error)) {
                let toastMsg = submission?.error.indexOf("utr_number") > -1 ? "Invalid UTR" : "Something went wrong";
                addToast("error", toastMsg, 3000);
                setLoading(false);
                return;
            }

            if (!u.isNonEmptyString(submission?.status)) {
                addToast("error", "Something went wrong", 3000);
                setLoading(false);
                return;
            }

            setPage("done");
        } catch (error) {
            console.log("error with finish submit", error?.message);
            addToast("error", "Something went wrong");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (turnstile_token) {
            if (usedTokens.indexOf(turnstile_token) === -1) finishSubmission();
            usedTokens.push(turnstile_token);
        }
    }, [turnstile_token]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!utrValid) {
                addToast("error", "Invalid UTR", 2000);
                return;
            }
            setLoading(true);

            setTurnstile(true);
        } catch (error) {
            console.log("error with handle submit", error?.message);
            addToast("error", "Something went wrong");
        } finally {
            setLoading(false);
        }
    };

    const copyToClipboard = useCallback(() => {
        navigator.clipboard.writeText(address);
        addToast("success", "Copied to clipboard!", 2400);
    }, [address]);

    const handleUtrChange = (e) => {
        const newUtr = e.target.value;
        setUtr(newUtr);
        setUtrValid(isValidUTR(newUtr));
    };

    try {
        return (
            <div className="bg-white sm:px-[4vw] sm:py-[2.4vw] bg-transparent flex justify-center">
                <div className="flex flex-col sm:p-2 bg-white rounded-[12px] sm:ring-1 sm:ring-gray-300 w-full sm:max-w-[540px] min-h-[0px] pb-[40px]">
                    <PayHeader />
                    <div className="p-[4vw] pt-[40px]">
                        <div className="flex flex-col items-center pt-0 w-full max-w-lg mx-auto">
                            <div className="flex flex-col items-center mb-[12px]">
                                <QRCode value={upiLink} />
                            </div>
                            <div
                                className={u.classNames(
                                    // "inline-flex items-center mb-[30px] ring-blu_med bg-opacity-0 bg-blu",
                                    "inline-flex items-center mb-[30px] ring-gray-300 bg-opacity-0 bg-blu",
                                    "group cursor-pointer rounded-full p-[4px] px-[8px] mb-[50px]",
                                    "ring-1 ring-inset ring-gray-300",
                                    "hover:ring-blu_med hover:bg-opacity-5"
                                )}
                            >
                                <div onClick={copyToClipboard} className="flex-grow flex justify-center no-outline">
                                    <span className="text-sm font-semibold text-black group-hover:text-blu_med">{address}</span>
                                </div>
                                <div className="flex items-center ml-[4px]">
                                    <DocumentDuplicateIcon className="h-4 w-4 text-blu_med group-hover:text-blu_med" />
                                </div>
                            </div>
                            <div className="w-full text-center">
                                <div className="flex items-center justify-center">
                                    <p className="text-sm font-semibold text-gray-900 mr-2">UTR Number</p>
                                    <HintCircle />
                                </div>
                            </div>
                            <div className="w-full relative mt-2 mb-[24px] max-w-[400px]">
                                <input
                                    type="text"
                                    // className="w-full rounded-md border-1 border-gray-300 p-3 text-gray-900 text-center ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blu sm:leading-6 focus:outline-none focus:border-blu focus:shadow-outline-blu"
                                    className={u.classNames(
                                        "w-full rounded-md border-1 p-3 text-gray-900 text-center ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:leading-6 focus:outline-none",
                                        utrValid === null ? "ring-gray-300" : utrValid ? "ring-green_ok focus:ring-green_ok" : "ring-red_err focus:ring-red_err"
                                    )}
                                    placeholder="Enter UTR Number"
                                    value={utr}
                                    // onChange={(e) => setUtr(e.target.value)}
                                    onChange={handleUtrChange}
                                />
                                {utrValid !== null && (
                                    <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                                        {utrValid ? (
                                            <CheckCircleIcon className="h-5 w-5 text-green_ok" />
                                        ) : (
                                            <ExclamationCircleIcon className="h-5 w-5 text-red_err" />
                                        )}
                                    </div>
                                )}
                            </div>
                            <SubmitButton onClick={handleSubmit} />
                        </div>
                    </div>
                </div>
            </div>
        );
        // eslint-disable-next-line no-unreachable
    } catch (error) {
        console.log("pay component error");
        console.log(error?.message);
        setError(true);
        return;
    }
}

// 678678678678
