// eslint-disable-next-line no-unused-vars
import nitro from "./nitro.js";
import u from "./utils.js";

function getRandomUPIOption(channel_types) {
    const upiChannel = channel_types.find((channel) => channel.type === "upi");

    if (upiChannel && upiChannel.options.length > 0) {
        const randomIndex = Math.floor(Math.random() * upiChannel.options.length);
        return upiChannel.options[randomIndex];
    } else {
        return null;
    }
}

export default async function loadPage({ app_id, auth_token, setPage, setError, setLoading, setChannel, setAmount }) {
    try {
        const { channel_types, amount } = await nitro.getChannels({
            setError,
            setLoading,
            app_id,
            auth_token,
        });

        if (!u.isNonEmptyArray(channel_types) || !u.isPositiveNumber(amount)) throw Error("Invalid channel_types or amount");

        const channelSelection = getRandomUPIOption(channel_types);

        setChannel(channelSelection);
        setAmount(amount);
        setPage("pay");
    } catch (error) {
        console.log("error loading page");
        console.log(error?.message);
        setError(true);
    } finally {
        setLoading(false);
    }
}
